// 当前前台是否为开发模式
const isDev = process.env.NODE_ENV === 'development'
let supplierSiteUrl = isDev ? 'http://192.168.0.154:8091' : 'https://mtest.bjftyx.net/web'
let supplierUrl = isDev ? 'http://192.168.0.154' : 'https://mtest.bjftyx.net/supplier'
let supplierPortUrl = isDev ? 'http://192.168.0.154:8084' : 'https://m.bjftyx.net/supplier-api'
let supplierApiUrl = isDev ? 'http://192.168.0.154:8084' : 'https://mtest.bjftyx.net/supplier-api'
switch (process.env.VUE_APP_APP_HOST) {
	case 'development': //测试
		supplierSiteUrl = 'https://mtest.bjftyx.net/web'
		supplierUrl = 'https://mtest.bjftyx.net/supplier'
		supplierPortUrl = 'https://mtest.bjftyx.net/supplier-api'
		supplierApiUrl = 'https://mtest.bjftyx.net/supplier-api'
		break
	case 'production': //正式
		supplierSiteUrl = 'https://jc.bjftyx.net/web'
		supplierUrl = 'https://jc.bjftyx.net/supplier'
		supplierPortUrl = 'https://jc.bjftyx.net/supplier-api'
		supplierApiUrl = 'https://jc.bjftyx.net/supplier-api'
		break
}
export default {
	pageSize: 10,
	supplierSiteUrl: supplierSiteUrl,
	supplierUrl: supplierUrl,
	supplierPortUrl: supplierPortUrl,
	supplierApiUrl: supplierApiUrl, //文件上传
	isDev: true,
}